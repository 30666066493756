import React from 'react'
import styled from 'styled-components'
import {
  string, arrayOf, number, func,
} from 'prop-types'

import Button from '../Button'

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 80px;
  max-width: 400px;
  margin: 0 auto;
`

export const Header = styled.div`
  display: flex;
  padding-bottom: 5px;
`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-size: ${({ theme: { size } }) => size.fontSize.s};
  flex: 1;
  padding-left: 10px;
`

export const Container = styled.div`
  position: absolute;
  top: 60px;
  left: 0;
  min-height: 80px;
  padding: 10px;
  width: 100vw;
  background-color: ${({ theme: { color } }) => color.primary.dark};
  display: flex;
  flex-direction: column;
  z-index: 9;

  @media(min-width: ${({ theme: { breakPoint } }) => breakPoint.laptop}) {
    width: 300px;

    ${InnerContainer} {
      width: 300px;
    }

    ${Header} {
      flex-direction: column;
    }

    ${TextContainer} {
      align-items: flex-start;
      padding: 5px;
    }
  }
`

export const Amount = styled.div`
  font-size: ${({ theme: { size } }) => size.fontSize.xl};
  color: ${({ theme: { color } }) => color.text};
  display: flex;
  align-items: center;
`

export const Text = styled.div`
  color: ${({ theme: { color } }) => color.secondary.main};
`

const PricePreviw = ({ amount, text, openForm }) => {
  const showText = text && text.length > 0
  const cleanAmount = (amount || 0).toFixed(2)

  return (
    <Container>
      <InnerContainer>
        <Header>
          <Amount>{`${cleanAmount} €`}</Amount>
          {showText && (
          <TextContainer>
            {
              text.map(t => (
                <Text key={t}>{`+ ${t}`}</Text>
              ))
            }
          </TextContainer>
          )}
        </Header>
        <Button onClick={openForm}>Envoyer le devis</Button>
      </InnerContainer>
    </Container>
  )
}

PricePreviw.defaultProps = {
  openForm: () => {},
}

PricePreviw.propTypes = {
  amount: number.isRequired,
  text: arrayOf(string).isRequired,
  openForm: func,
}

export default PricePreviw
