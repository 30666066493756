import styled from 'styled-components'
import Img from 'gatsby-image'
import SelectBase from '../Select'

export const Content = styled.div`
  padding: 15px;
  padding-top: 30px;
  max-width: 800px;
  margin: 0 auto;

  @media(min-width: ${({ theme: { breakPoint } }) => breakPoint.laptop}) {
    padding-top: 30px !important;
  }
`

export const Description = styled.div`
  text-align: justify;
  font-size: ${({ theme: { size } }) => size.fontSize.m};
  max-width: 500px;
  margin: 0 auto;
  line-height: 1.7rem;
`

export const DevisTitle = styled.div`
  color: ${({ theme: { color } }) => color.secondary.main};
  font-size: ${({ theme: { size } }) => size.fontSize.xl};
  margin-top: 40px;
  margin-bottom: 15px;
  text-align: center;
`
export const DevisSubtitle = styled(DevisTitle)`
  font-size: ${({ theme: { size } }) => size.fontSize.s};
  margin-top: 0;
  margin-bottom: 20px;
  color: ${({ theme: { color } }) => color.secondary.dark};
`

export const Section = styled.div`
  padding: 25px 0px;
`

export const TitleSection = styled.div`
  padding-top: 30px;
  padding-bottom: 10px;
  text-align: center;
  color: ${({ theme: { color } }) => color.secondary.main};
  font-size: ${({ theme: { size } }) => size.fontSize.m};
`

export const ListContainer = styled.div`
  padding: 15px;
  max-width: 500px;
  margin: 0 auto;
`

export const ListTitle = styled.div`
  font-size: ${({ theme: { size } }) => size.fontSize.m};
  padding-bottom: 10px;
`

export const IncludedList = styled.ul`
  list-style-type: circle;
  list-style-position: outside;
`
export const ListLine = styled.li`
  padding-bottom: 10px;
  font-size: ${({ theme: { size } }) => size.fontSize.m};
  text-align: justify;
`

export const ImagesContainer = styled.div`
  display: flex;
  justify-content: center;
`

export const OptionContainer = styled.div`
  cursor: pointer;
`

export const OptionFog = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: ${({ isSelected }) => isSelected ? '0.2' : '0.7'};
  background-color: ${({ isSelected, theme: { color } }) => isSelected
    ? color.secondary.main
    : color.primary.main};
`

export const OptionName = styled.div`
  color: ${({ isSelected, theme: { color } }) => isSelected
    ? color.secondary.main
    : color.text};
  text-align: center;
`

export const ImgContainer = styled.div`
  margin: 10px;
  border-radius: 7px;
  border: 1px solid ${({ isSelected, theme: { color } }) => isSelected
    ? color.secondary.main
    : color.primary.main};
  position: relative;
  width: 150px;
  overflow: hidden;
`

export const Image = styled(Img)`
  border-radius: 7px;
`

export const Select = styled(SelectBase)`
  margin: 10px auto 0px;
`

export const CheckBoxWrapper = styled.div`
  padding: 10px 0;
  max-width: 500px;
  margin: 0 auto;
`
