import React from 'react'
import styled from 'styled-components'
import {
  arrayOf, shape, string, func, bool,
} from 'prop-types'
import { ArrowUp } from './Icons'

const Container = styled.div`
  position: relative;
  margin-top: 25px;
  max-width: 330px;
`

const ArrowIcon = styled(ArrowUp)`
  position: absolute;
  transform: rotate(180deg);

  right: 9px;
  top: 17px;
  font-size: 19px !important;
`

const SelectBsic = styled.select`
  width: 100%;
  height: 50px;
  padding: 10px;
  outline: none;
  font-size: ${({ theme: { size: { fontSize } } }) => fontSize.m};
  background-color: transparent;
  border: 2px solid ${({ theme: { color } }) => color.primary.light};
  border-radius: 7px;
  color: ${({ theme: { color } }) => color.text};

  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  &::-ms-expand {
    display: none;
  }
`

export const Option = styled.option`
  background-color: ${({ theme: { color } }) => color.primary.light};
  color: ${({ theme: { color } }) => color.text};
  border: 1px solid ${({ theme: { color } }) => color.text};
`

export const OptionGroup = styled.optgroup`
  background-color: ${({ theme: { color } }) => color.primary.light};
  color: ${({ theme: { color } }) => color.text_negative};
  border: 1px solid ${({ theme: { color } }) => color.text};
  font-weight: bold;
`

const Select = ({
  value, onChange, options, className, hasGroup,
}) => {
  if (!hasGroup) {
    return (
      <Container className={className}>
        <SelectBsic value={value} onChange={onChange}>
          {
            options.map(v => (
              <Option key={v.id} value={v.id}>
                {v.title}
              </Option>
            ))
          }
        </SelectBsic>
        <ArrowIcon />
      </Container>
    )
  }

  return (
    <Container className={className}>
      <SelectBsic value={value} onChange={onChange}>
        {
            (options || []).map(opt => (
              <OptionGroup key={opt.name} label={opt.name}>
                {
                  opt.options.map(o => (
                    <Option key={o.id} value={o.id}>
                      {o.title}
                    </Option>
                  ))
                }
              </OptionGroup>
            ))
          }
      </SelectBsic>
      <ArrowIcon />
    </Container>
  )
}

Select.defaultProps = {
  className: '',
  hasGroup: false,
}

Select.propTypes = {
  className: string,
  value: string.isRequired,
  onChange: func.isRequired,
  options: arrayOf(shape({})).isRequired,
  hasGroup: bool,
}

export default Select
