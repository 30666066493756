import React from 'react'
import { oneOf } from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import Img from 'gatsby-image'

const query = graphql`
  {
    tshirt: file(relativePath: {eq: "print/t-shirt-page.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 500, maxHeight: 500, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bags: file(relativePath: {eq: "print/bags-page.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 500, maxHeight: 500, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    paper: file(relativePath: {eq: "print/paper-print-page.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 500, maxHeight: 500, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    cabs: file(relativePath: {eq: "musicgear/cabs-page.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 500, maxHeight: 500, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    pedalboards: file(relativePath: {eq: "musicgear/pedalboards-page.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 500, maxHeight: 500, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    hardcase: file(relativePath: {eq: "musicgear/hardcase-page.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 500, maxHeight: 500, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    guitar: file(relativePath: {eq: "guitar/guitar-home.jpeg"}) {
      childImageSharp {
        fluid(maxWidth: 500, maxHeight: 500, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export const ImgContainer = styled.div`
  width: 500px;
  margin: 0px auto;
  max-width: 100vw;
  overflow: hidden;
  padding-top: 16px;
`

const PageImage = ({ page }) => {
  const data = useStaticQuery(query)

  let img = null
  if (page === 'tshirt') {
    img = data.tshirt
  }
  if (page === 'bags') {
    img = data.bags
  }
  if (page === 'paper') {
    img = data.paper
  }
  if (page === 'cabs') {
    img = data.cabs
  }
  if (page === 'pedalboards') {
    img = data.pedalboards
  }
  if (page === 'hardcase') {
    img = data.hardcase
  }
  if (page === 'guitar') {
    img = data.guitar
  }

  if (!img) return null

  return (
    <ImgContainer>
      <Img fluid={img.childImageSharp.fluid} />
    </ImgContainer>
  )
}

PageImage.propTypes = {
  page: oneOf([
    'tshirt', 'bags', 'paper', 'cabs', 'pedalboards', 'hardcase', 'guitar',
  ]).isRequired,
}

export default PageImage
