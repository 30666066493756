/* eslint-disable max-len */
/* eslint-disable react/state-in-constructor */
import React, { useState, useReducer } from 'react'
import PageImage from '../PageImage'

import {
  Content,
  Description,
} from '../Cabs/styles'

import PricePreviw from '../Cabs/PricePreviw'
import Form, { Dialog } from '../Form'

import { ImageBlock } from './ImageBlock'

import * as S from './style'

import {
  bindingCorpOptions,
  bindingMancheOptions,
  boisCorpOptions,
  boisMancheOptions,
  chevaletOptions,
  configElectroniqueOptions,
  description,
  finitionCorpOptions,
  finitionMancheOptions,
  frettesOptions,
  initialPrice,
  mechaniquesOptions,
  microChevaletOptions,
  microMancheOptions,
  pickguardOptions,
  profileMancheOptions,
  repereToucheOptions,
  silletTeteOptions,
  toucheOptions,
  allOptions,
  diapasonOptions,
} from './data'

import { getGuitarDevis, getTotal } from './utils'

const Guitar = () => {
  const [showForm, toggleForm] = useReducer(s => !s, false)
  const [optionsValues, setOptionsValues] = useState(() => allOptions.reduce((acc, opt) => ({
    ...acc,
    [opt.key]: opt.options[0].id, // Initialize each select with the first option
  }), {}))

  const handleOnChange = key => e => {
    const { value } = e.target
    setOptionsValues(prev => ({ ...prev, [key]: value }))
  }

  const total = getTotal(allOptions, optionsValues, initialPrice)

  const devisResume = getGuitarDevis(allOptions, optionsValues, total)

  return (
    <>
      <PricePreviw
        amount={total}
        text={[]}
        openForm={toggleForm}
      />
      <Dialog show={showForm} onClose={toggleForm}>
        <Form
          title="Envoyer le devis"
          emailSubject="Devis Music Gear"
          withDevis
          devis={devisResume}
        />
      </Dialog>
      <PageImage page="guitar" />
      <Content>
        {description.map((d, i) => (
        // eslint-disable-next-line react/no-array-index-key
          <div style={{ paddingBottom: 15 }} key={i}>
            <Description>{d}</Description>
          </div>
        ))}
        <S.DevisTitle>Configurez votre Guitare</S.DevisTitle>

        {/* SECTION 1 Les Bois */}
        <S.SectionWrapper>
          <S.TitleSection>Les Bois</S.TitleSection>
          <S.Section>
            <S.LeftSection>
              <S.SelectorWrapper>
                <S.SelectLabel>Bois corps</S.SelectLabel>
                <S.Select
                  options={boisCorpOptions.options}
                  value={optionsValues[boisCorpOptions.key]}
                  onChange={handleOnChange(boisCorpOptions.key)}
                />
              </S.SelectorWrapper>

              <S.SelectorWrapper>
                <S.SelectLabel>Bois manche</S.SelectLabel>
                <S.Select
                  options={boisMancheOptions.options}
                  value={optionsValues[boisMancheOptions.key]}
                  onChange={handleOnChange(boisMancheOptions.key)}
                />
              </S.SelectorWrapper>

              <S.SelectorWrapper>
                <S.SelectLabel>Touche</S.SelectLabel>
                <S.Select
                  options={toucheOptions.options}
                  value={optionsValues[toucheOptions.key]}
                  onChange={handleOnChange(toucheOptions.key)}
                />
              </S.SelectorWrapper>

              <S.SelectorWrapper>
                <S.SelectLabel>Profils de manche</S.SelectLabel>
                <S.Select
                  options={profileMancheOptions.options}
                  value={optionsValues[profileMancheOptions.key]}
                  onChange={handleOnChange(profileMancheOptions.key)}
                />
              </S.SelectorWrapper>

              <S.SelectorWrapper>
                <S.SelectLabel>Diapason</S.SelectLabel>
                <S.Select
                  options={diapasonOptions.options}
                  value={optionsValues[diapasonOptions.key]}
                  onChange={handleOnChange(diapasonOptions.key)}
                />
              </S.SelectorWrapper>

            </S.LeftSection>
            <S.RightSection>
              <ImageBlock name="bois" />
            </S.RightSection>
          </S.Section>
        </S.SectionWrapper>

        {/* SECTION 2 Binding & Pickguard */}
        <S.SectionWrapper>
          <S.TitleSection>Binding & Pickguard</S.TitleSection>
          <S.Section>
            <S.LeftSection>
              <S.SelectorWrapper>
                <S.SelectLabel>Binding corps</S.SelectLabel>
                <S.Select
                  options={bindingCorpOptions.options}
                  value={optionsValues[bindingCorpOptions.key]}
                  onChange={handleOnChange(bindingCorpOptions.key)}
                />
              </S.SelectorWrapper>

              <S.SelectorWrapper>
                <S.SelectLabel>Binding manche</S.SelectLabel>
                <S.Select
                  options={bindingMancheOptions.options}
                  value={optionsValues[bindingMancheOptions.key]}
                  onChange={handleOnChange(bindingMancheOptions.key)}
                />
              </S.SelectorWrapper>

              <S.SelectorWrapper>
                <S.SelectLabel>Pickguard</S.SelectLabel>
                <S.Select
                  options={pickguardOptions.options}
                  value={optionsValues[pickguardOptions.key]}
                  onChange={handleOnChange(pickguardOptions.key)}
                />
              </S.SelectorWrapper>

              <S.SelectorWrapper>
                <S.SelectLabel>Repères de touche</S.SelectLabel>
                <S.Select
                  options={repereToucheOptions.options}
                  value={optionsValues[repereToucheOptions.key]}
                  onChange={handleOnChange(repereToucheOptions.key)}
                />
              </S.SelectorWrapper>

            </S.LeftSection>
            <S.RightSection>
              <ImageBlock name="binding1" />
            </S.RightSection>
          </S.Section>
        </S.SectionWrapper>

        {/* SECTION 3 Le Hardware */}
        <S.SectionWrapper>
          <S.TitleSection>Le Hardware</S.TitleSection>
          <S.Section>
            <S.LeftSection>
              <S.SelectorWrapper>
                <S.SelectLabel>Chevalet</S.SelectLabel>
                <S.Select
                  options={chevaletOptions.options}
                  value={optionsValues[chevaletOptions.key]}
                  onChange={handleOnChange(chevaletOptions.key)}
                />
              </S.SelectorWrapper>

              <S.SelectorWrapper>
                <S.SelectLabel>Mechaniques</S.SelectLabel>
                <S.Select
                  options={mechaniquesOptions.options}
                  value={optionsValues[mechaniquesOptions.key]}
                  onChange={handleOnChange(mechaniquesOptions.key)}
                />
              </S.SelectorWrapper>

              <S.SelectorWrapper>
                <S.SelectLabel>Sillet de tête</S.SelectLabel>
                <S.Select
                  options={silletTeteOptions.options}
                  value={optionsValues[silletTeteOptions.key]}
                  onChange={handleOnChange(silletTeteOptions.key)}
                />
              </S.SelectorWrapper>

              <S.SelectorWrapper>
                <S.SelectLabel>Frettes</S.SelectLabel>
                <S.Select
                  options={frettesOptions.options}
                  value={optionsValues[frettesOptions.key]}
                  onChange={handleOnChange(frettesOptions.key)}
                />
              </S.SelectorWrapper>

            </S.LeftSection>
            <S.RightSection>
              <ImageBlock name="hardware1" />
            </S.RightSection>
          </S.Section>
        </S.SectionWrapper>

        {/* SECTION 4 Micro & Electronique */}
        <S.SectionWrapper>
          <S.TitleSection>Micros & Electronique</S.TitleSection>
          <S.Section>
            <S.LeftSection>
              <S.SelectorWrapper>
                <S.SelectLabel>Micro chevalet</S.SelectLabel>
                <S.Select
                  options={microChevaletOptions.options}
                  value={optionsValues[microChevaletOptions.key]}
                  onChange={handleOnChange(microChevaletOptions.key)}
                />
              </S.SelectorWrapper>

              <S.SelectorWrapper>
                <S.SelectLabel>Micro manche</S.SelectLabel>
                <S.Select
                  options={microMancheOptions.options}
                  value={optionsValues[microMancheOptions.key]}
                  onChange={handleOnChange(microMancheOptions.key)}
                />
              </S.SelectorWrapper>

              <S.SelectorWrapper>
                <S.SelectLabel>Configuration électronique</S.SelectLabel>
                <S.Select
                  options={configElectroniqueOptions.options}
                  value={optionsValues[configElectroniqueOptions.key]}
                  onChange={handleOnChange(configElectroniqueOptions.key)}
                />
              </S.SelectorWrapper>

            </S.LeftSection>
            <S.RightSection>
              <ImageBlock name="electronique" />
            </S.RightSection>
          </S.Section>
        </S.SectionWrapper>

        {/* SECTION 5 FINITION */}
        <S.SectionWrapper>
          <S.TitleSection>Finitions</S.TitleSection>
          <S.Section>
            <S.LeftSection>
              <S.SelectorWrapper>
                <S.SelectLabel>Finition manche</S.SelectLabel>
                <S.Select
                  options={finitionMancheOptions.options}
                  value={optionsValues[finitionMancheOptions.key]}
                  onChange={handleOnChange(finitionMancheOptions.key)}
                />
              </S.SelectorWrapper>

              <S.SelectorWrapper>
                <S.SelectLabel>Finitions corps</S.SelectLabel>
                <S.Select
                  options={finitionCorpOptions.options}
                  value={optionsValues[finitionCorpOptions.key]}
                  onChange={handleOnChange(finitionCorpOptions.key)}
                />
              </S.SelectorWrapper>

            </S.LeftSection>
            <S.RightSection>
              <ImageBlock name="finition" />
            </S.RightSection>
          </S.Section>
        </S.SectionWrapper>

      </Content>
    </>)
}

export default Guitar
